body {
    @apply text-gray-500;
    @apply text-sm;
}
a {
    transition: all ease .3s;
}
h2 {
    @apply text-gray-800 font-semibold text-base;
}

.btn {
    @apply bg-blue-600 text-white text-base font-medium inline-flex items-center justify-center text-center p-9px px-5 rounded-md hover:bg-blue-800 focus:outline-none h-10 cursor-pointer;
    &--border {
        @apply bg-white text-gray-700 border border-gray-300 hover:bg-gray-100;
    }
    &--full {
        @apply w-full;
    }
    &--red {
        @apply bg-red-500 hover:bg-red-600;
    }
    &--green {
        @apply bg-green-500 hover:bg-green-600
    }
}


.card {
    @apply rounded-sm shadow-sm border border-gray-200 h-360px;
    &--attendancechart {
        height: 319px;
    }
}
[data-simplebar] {
    max-height: calc(100vh - 77px);
}
table {
    @apply w-full border-t border-gray-200;
}
td {
    @apply pl-10 py-3 border-b border-gray-200;
}
thead {
    @apply bg-gray-50 text-xs font-medium text-gray-800 uppercase;
}

span.status {
    @apply inline-block text-yellow-800 bg-yellow-100 font-medium text-xs px-10px py-2px rounded-lg;
    &--red {
        @apply text-red-800 bg-red-100;
    }
    &--green {
        @apply text-green-800 bg-green-100;
    }
    &--gray {
        @apply text-black bg-gray-100;
    }
}
.tab {
    @apply px-10 flex;
    li {
        @apply pr-7;
    }
    a {
        @apply inline-block p-1 pb-3 border-b-2 border-transparent text-gray-500 font-medium;
        &.active {
            @apply text-blue-800 border-blue-800;
        }
    }
}
@media (max-width: 991px) {
    .sidebar {
        border: 0;
        width: 100%;
    }
    [data-simplebar] {
        max-height: 100% !important;
    }
    .simplebar-wrapper .simplebar-mask, .simplebar-wrapper .simplebar-offset {
        position: relative;
    }
    .simplebar-placeholder {
        display: none;
    }
}
.pagination-bar {
   width: 100%;
   width: -moz-available;
   width: stretch;
   width: -webkit-fill-available;
}
.user-profile-side-bar {
    width: 80px !important;
    height: 80px !important;
}
.css-1okebmr-indicatorSeparator{
    display: none;
}
.css-1u9des2-indicatorSeparator {
    display: none;
}
.custom-select-container {
    [class$="-control"] {
        max-height: 38px;
        min-width: 150px;
        @apply border-gray-300;
        &:focus {
            @apply border-blue-400  border-none outline-none;
        }
        &:hover {
            @apply border-blue-400;
        }
    }
}


.react-responsive-modal-modal{
    @apply rounded-lg
}
.employee-info-card td{
    @apply px-1 py-2 border-0;
}
.employee-info-card .title{
    @apply text-sm;
}
.employee-info-card .data{
    @apply text-sm text-black;
}
@media (max-width: 991px) {
    .sidebar {
        border: 0;
        width: 100%;
    }
    [data-simplebar] {
        max-height: 100% !important;
    }
    .simplebar-wrapper .simplebar-mask, .simplebar-wrapper .simplebar-offset {
        position: relative;
    }
    .simplebar-placeholder {
        display: none;
    }
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.react-responsive-modal-modal {
    max-width: 1400px !important;
}

.edit_time_container {
    visibility: hidden;
}
.timeline_container:hover .edit_time_container{
    visibility: visible;
}

.ant-collapse-content, .ant-collapse-content-active, .ant-collapse-item {
    border-radius: 0px !important;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.list {
    @apply px-4;
    li {
        @apply flex items-center gap-5;
        + li {
            .text {
                @apply border-t border-gray-200;
            }
        }
    }
    h3 {
        @apply text-gray-800 font-semibold text-xs;
    }
}
.icon {
    @apply text-gray-600;
}
.text {
    @apply py-4 flex-1 flex items-center;
}
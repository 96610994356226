.content {
    width: auto;
}
.swiper {
    @apply overflow-visible px-10;
}
.swiper-slide {
    width: 234px;
}
.swiper-button-next:after, .swiper-button-prev:after {
    @apply font-bold;
    --swiper-navigation-size: 15px;
}
.swiper-button-prev, .swiper-button-next{
    --swiper-navigation-color: #6B7280;
    @apply z-30;
}
.swiper-button-prev {
    @apply left-0;
}
.swiper-button-next {
    @apply right-0;
}
.swiper {
    &:before , &:after {
        content: '';
        @apply absolute top-0 bottom-0 bg-white w-10 z-10;
    }
    &:before {
        @apply left-0;
    }
    &:after {
        @apply right-0;
    }
}
.stepper {
    @apply ml-18px;
    li {
        > a {
            @apply flex;
            &.active {
                .icon {
                    @apply border-green-400 bg-white;
                    &:before {
                        content: '';
                        @apply w-10px h-10px bg-green-400 rounded-full;
                    }
                }
                h3 {
                    @apply text-green-700;
                }
            }
        }
        &.active ~ li {
            @apply pointer-events-none;
            .icon {
                @apply  border-gray-300 bg-white;
            }
            h3 {
                @apply text-gray-900;
            }
        }
        .icon {
            content: '';
            @apply w-8 h-8 rounded-full border-2 bg-green-400 border-green-400  flex-shrink-0 mr-4 -ml-4 grid place-items-center;
            &:before {
                content: "\e905";
                font-family: 'icons';
                font-size: 10px;
                color: #fff;
                speak: never;
                font-style: normal;
                font-weight: normal;
                line-height: 1;
            }
        }
        &:last-child {
            .icon {
                align-self: flex-end;
            }
        }
        + li {
            @apply mt-8;
        }
    }
    h3 {
        @apply text-xs uppercase font-semibold leading-tight;
    }
    p {
        @apply leading-tight;
    }
    &.stepper2 {
        @apply ml-7px;
        li {
            h3 {
                @apply capitalize;
            }
            &.active {
                .icon {
                    @apply border-0 bg-blue-200;
                    &:before {
                        content: '';
                        @apply w-2 h-2 bg-blue-700 rounded-full;
                    }
                }
                h3 {
                    @apply text-blue-800;
                }
            }
            .icon {
                @apply w-4 h-4 -ml-2 bg-blue-800 border-0;
                &:before {
                    content: "\e91c";
                    font-family: 'icons';
                    font-size: 6px;
                    @apply rounded-full;
                }
            }
            &.active ~ li {
                .icon {
                    @apply  bg-transparent;
                    &:before {
                        content: '';
                        @apply bg-blue-800 w-2 h-2 bg-gray-300;
                    }
                }
                h3 {
                    @apply text-gray-900;
                }
            }
        }
    }
}
.tab-list{
    @apply w-full table border-b border-solid border-gray-300 py-4 px-8 rounded-md;
        .tab-li{
            @apply lg:table-cell text-sm font-medium leading-6 text-gray-500 not-italic cursor-pointer;
        }
        .active-tab{
            text-underline-offset: 21px;
            @apply underline decoration-2 decoration-blue-600 text-blue-600 ;
        }
}
.tab-head{
    @apply flex justify-between;
}
.preference-tab-head{
    @apply flex justify-start gap-8;
}

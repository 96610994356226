.slide-pane__content{
    padding: 30px 0px;
}
.slide-pane__overlay{
    z-index: 10;
}
.search {
   input[type="text"] {
       @apply   pl-8;
   }
}
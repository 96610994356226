.summary {
    @apply flex flex-wrap text-xs;
    li {
        @apply pr-2 flex-1 mb-3;
        flex: 1 1 25%;
        p + p {
            @apply font-semibold text-gray-800;
        }
        @media (max-width: 1366px) {
            flex: 1 1 33.33%;
        }
        @media (max-width: 1299px) {
            flex: 1 1 50%;
        }
        @media (max-width: 991px) {
            flex: 1 1 100%;
        }
    }
}
.pay {
    @apply bg-white mb-6;
    li {
        @apply flex justify-between;
        + li {
            @apply mt-3;
        }
    }
}
@font-face {
    font-family: 'icons';
    src:  url('/fonts/icons.ttf?mk5w0h') format('truetype'),
      url('/fonts/icons.woff?mk5w0h') format('woff'),
      url('/fonts/icons.svg?mk5w0h#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
  
  [class^="ico-"], [class*=" ico-"] {
    font-family: 'icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

 
  
  
  .ico-star:before {
    content: "\e93c";
  }
  .ico-edit2:before {
    content: "\e92a";
  }
  .ico-desktop:before {
    content: "\e92e";
  }
  .ico-mobile:before {
    content: "\e92d";
  }
  .ico-attachment:before {
    content: "\e92f";
  }
  .ico-securitycheck:before {
    content: "\e931";
  }
  .ico-work:before {
    content: "\e932";
  }
  .ico-check2:before {
    content: "\e934";
  }
  .ico-check1:before {
    content: "\e933";
  }
  .ico-download2:before {
    content: "\e935";
  }
  .ico-upload2:before {
    content: "\e936";
  }
  .ico-exit:before {
    content: "\e937";
  }
  .ico-copy:before {
    content: "\e938";
  }
  .ico-adduser:before {
    content: "\e939";
  }
  .ico-printer:before {
    content: "\e93b";
  }
  .ico-preference:before {
    content: "\e93a";
  }
  .ico-folder:before {
    content: "\e92c";
  }
  .ico-delete:before {
    content: "\e92b";
  }
  .ico-clock1:before {
    content: "\e930";
  }
  .ico-right:before {
    content: "\e926";
  }
  .ico-dropdown:before {
    content: "\e927";
  }
  .ico-reply:before {
    content: "\e923";
  }
  .ico-clock:before {
    content: "\e918";
  }
  .ico-more:before {
    content: "\e91b";
  }
  .ico-briefcase:before {
    content: "\e920";
  }
  .ico-finance:before {
    content: "\e91d";
  }
  .ico-home:before {
    content: "\e902";
  }
  .ico-cash:before {
    content: "\e917";
  }
  .ico-education:before {
    content: "\e919";
  }
  .ico-edit:before {
    content: "\e928";
  }
  .ico-grid:before {
    content: "\e91e";
  }
  .ico-table:before {
    content: "\e91f";
  }
  .ico-drag:before {
    content: "\e921";
  }
  .ico-close:before {
    content: "\e922";
  }
  .ico-left:before {
    content: "\e91a";
  }
  .ico-bookmark:before {
    content: "\e924";
  }
  .ico-search:before {
    content: "\e90a";
  }
  .ico-plus:before {
    content: "\e90b";
  }
  .ico-bell:before {
    content: "\e90c";
  }
  .ico-settings:before {
    content: "\e90d";
  }
  .ico-report:before {
    content: "\e90e";
  }
  .ico-notes:before {
    content: "\e90f";
  }
  .ico-assets:before {
    content: "\e910";
  }
  .ico-calendar:before {
    content: "\e911";
  }
  .ico-card:before {
    content: "\e912";
  }
  .ico-chart:before {
    content: "\e913";
  }
  .ico-people:before {
    content: "\e914";
  }
  .ico-office:before {
    content: "\e915";
  }
  .ico-bulb:before {
    content: "\e916";
  }
  .ico-tick2:before {
    content: "\e91c";
  }
  .ico-down:before {
    content: "\e909";
  }
  .ico-chat:before {
    content: "\e929";
  }
  .ico-check:before {
    content: "\e925";
  }
  .ico-date:before {
    content: "\e908";
  }
  .ico-doc:before {
    content: "\e900";
  }
  .ico-download:before {
    content: "\e901";
  }
  .ico-email:before {
    content: "\e903";
  }
  .ico-phone:before {
    content: "\e904";
  }
  .ico-tick:before {
    content: "\e905";
  }
  .ico-upload:before {
    content: "\e906";
  }
  .ico-user:before {
    content: "\e907";
  }
.ico-briefcase, .ico-education {
  @apply text-xl;
}
.ico-phone {
  font-size: 1.2rem;
}
.ico-down, .ico-dropdown {
  font-size: 6px;
}
.ico-user {
  @apply text-base;
}
.ico-more {
  font-size: 6px;
  @apply leading-none;
}
.ico-bookmark{
  @apply text-lg leading-none;
}
.rbc-toolbar .rbc-btn-group:first-child button:first-child{
    //Today button
    display: none;
}
.rbc-toolbar .rbc-btn-group:first-child button:first-child:hover {
    //today button hover styles
    border-radius: 5px;
}
.rbc-toolbar .rbc-btn-group:first-child button:first-child:focus {
    //today button focus styles
    border-radius: 5px;
}

.rbc-toolbar .rbc-btn-group:first-child button:last-child{
    //Next month button
    background-color: transparent;
    border: none;
    color: #2a52b9;

}
.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
    //Previous month button
    background-color: transparent;
    border: none;
    color: #2a52b9;
}

.rbc-toolbar .rbc-btn-group {
    //next,prev,today button main div
    display: flex;
    border: none ;
    gap: 150px;
    flex-grow: 0;
}


.rbc-toolbar .rbc-btn-group:last-child {
    //Month, Week, Day, Agenda selector toolbar display hidden
    display: none;
}
.rbc-toolbar .rbc-toolbar-label {
    //Month label style
    font-weight: 500;
    color: black;
    flex-grow: 0;
    position: absolute;
    margin-left: 10px;
    right: 40px;
    min-width: 150px;
    text-align: center;
}

.rbc-month-view {
    // table style
    border-radius: 15px;
    overflow: hidden;
}

.rbc-header{
    // table header style
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #EFF1F5;
}

.rbc-toolbar .rbc-btn-group:first-child {
    // Next and previous btn main div
    border: none;
}

.rbc-today {
    // today date column
    background-color: #d5eaf8;
}

.rbc-row .rbc-date-cell button {
    //styles for dates in calendar
    color: #000;
    font-size: 10px;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 3px;

}

.rbc-row .rbc-current button{
    // styles for selected date in calendar
    color: #fff;
    background-color: #3466E7;
    border-radius: 999px;

}

.rbc-header span{
    //header styles
    color:#30374F;
}

.rbc-toolbar {
    //toolbar position set and content to end
    position: relative;
    justify-content: end;
}

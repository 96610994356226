.simple-card-div{
    @apply border border-gray-300 rounded-md p-3 overflow-auto;
    .title-icon{
        @apply mr-4;
        svg{
        @apply stroke-gray-500;
        }
    }
    // .sub-text{
    //     @apply pl-4
    // }
    label{
        @apply text-sm text-gray-900 font-medium;
    }
    p{
        @apply text-sm font-normal text-gray-500;
    }
    .edit-icon{
        @apply float-right cursor-pointer;
    }
    .footer-text{
        @apply pt-3
    }
}
label {
    @apply font-medium mb-1;
}

input[type="text"],[type="password"],[type="email"],[type="number"],[type="tel"], select, textarea {
    @apply border border-gray-300 rounded py-2 px-3 w-full;
    &:focus {
        @apply outline-blue-400;
    }
}
input[type="date"]{
    max-height: 38px;
    @apply border border-gray-300 rounded py-2 px-3 w-full;
    &:focus {
        @apply outline-blue-400;
    }
}
input[type="checkbox"] {
    @apply border border-gray-300 rounded w-4 h-4 rounded-sm;
}
input[type="radio"] {
    @apply border border-gray-300 w-4 h-4 rounded-sm;
}
label.switch {
    @apply inline-block relative w-11 h-6 cursor-pointer;
    input{
        @apply absolute left-0 top-0 opacity-0;
        &:checked {
            ~ span {
                @apply bg-blue-800;
                &:before {
                    left: 23px;
                }
            }
        }
    }
    span {
        @apply inline-block w-11 h-6 bg-gray-200 rounded-xl;
        transition: all ease .1s;
        &:before {
            @apply absolute w-5 h-5 rounded-full bg-white;
            content: '';
            left: 2px;
            top: 2px;
            transition: all ease .1s;
        }
    }
}
input[type="time"] {
    border: 1px solid #d1d5db;
    border-radius: 6px;
    height: 40px;
    width: 110px;
    padding: 5px;
}
.country-input{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.country-input-select {
    [class$="-control"] {
        border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    }
    
}
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(22%) sepia(100%) saturate(900%) hue-rotate(190deg);
}
.custom-input-container {
    width: 55px !important;
}

.information-card-div{
    @apply p-8 border border-gray-300 rounded-md w-full;
    .add-btn{
        @apply border border-gray-300 rounded-md text-sm font-medium text-gray-700 px-4 py-2 mt-5;
        span{
            @apply inline pl-2;
        }
        svg{
            @apply stroke-gray-900 inline;
        }
    }
    .header-div{
        @apply mb-3;
        label{
            @apply inline text-gray-800 text-base font-semibold pl-3;
        }
        .title-icon{
            svg{
            @apply stroke-blue-800
            }
        }
        .edit-icon{
            @apply float-right;
        }
        .title-icon{
            @apply bg-blue-50 rounded-full p-2 w-12
        }
    }
    .section{
        @apply border border-t-gray-200 border-r-0 border-l-0 border-b-0 w-full lg:table-fixed;
        td{
            @apply border-0 text-sm text-gray-800 font-medium py-2 text-left px-2;
        }
        .td-label{
            @apply text-gray-500 font-normal;
        }

    }
    .footer-table{
        @apply border-0 w-full;
        td{
            @apply border-0 text-sm text-gray-800 font-medium py-2 px-2 text-left;
        }
        .td-label{
            @apply text-gray-500 font-normal;
        } 
    }
    .section:first-of-type{
        @apply border-0;
    }
}
.tabs{
    padding: 0 16px 20px 16px;
}
.active-tabsnew  {
    // background:green;
    border-bottom: 2px solid;
}
  .contentnew {
    display: none;
  }
  .active-contentnew {
    display: block !important;
  }
.rbc-off-range{
    .rbc-button-link{
        @apply hidden
    }
}
.rbc-row {
    .rbc-date-cell{
        @apply text-left pl-2.5 pt-2.5
    }
}
.rbc-row-bg{
    .rbc-off-range-bg{
        @apply bg-gray-100
    }
}
.rbc-month-header{
    .rbc-header{
        @apply pt-2 pb-2 text-sm font-normal text-gray-900
    }
}
.rbc-row-segment{
    .rbc-event{
        @apply px-2.5 py-1 m-auto w-11/12 rounded-sm
    }
    .rbc-event-content{
        @apply text-xs font-medium text-gray-900
    }
}